import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Affiliates",
  "path": "/affiliates.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><a parentName="p" {...{
        "href": "http://dragonballvillage.com/"
      }}>{`DragonBall Village`}</a>{`
`}<a parentName="p" {...{
        "href": "http://gokusfirepalace.net/"
      }}>{`Goku's Fire Palace (800x600)`}</a>{`
`}<a parentName="p" {...{
        "href": "http://beckettdbz.com/"
      }}>{`Beckett's DBZ Site`}</a>{`
`}<a parentName="p" {...{
        "href": "http://dbzgtlegacy.com/"
      }}>{`DBZGT Legacy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      